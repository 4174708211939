import { twMerge } from "tailwind-merge";

export type ProgressBarProps = {
    complete: number;
    total: number;
    alwaysShowALittle?: boolean;
    className?: string;
};

export const ProgressBar = ({
    complete,
    total,
    alwaysShowALittle,
    className
}: ProgressBarProps): JSX.Element => (
    <div
        className={twMerge(
            // Note: A height and width are needed for this to render correctly
            "h-2 bg-[#14141414] rounded-full overflow-hidden",
            className
        )}
    >
        <div
            data-testid="bar-progress"
            className="h-full bg-success rounded-full"
            style={{
                width: `${Math.floor(
                    !alwaysShowALittle
                        ? (100 * complete) / total
                        : (100 * (complete + 1)) / (total + 1)
                )}%`
            }}
        />
    </div>
);
