import { ArrowLeft } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";
import PortalAnalytics from "PortalAnalytics";
import {
    baseButtonStyles,
    getButtonSizeStyle,
    getButtonVariantStyle
} from "components/common/v2/button/Button";
import { URI_GET_STARTED } from "constants/urls";
import { ProgressBar } from "./ProgressBar";
import { TOTAL_ONBOARDING_TASKS } from "./tasks";

type TaskProgressHeaderProps = {
    taskId?: string;
    tasksComplete: number;
};

export const TaskProgressHeader = ({
    taskId,
    tasksComplete
}: TaskProgressHeaderProps): JSX.Element => (
    <div
        className={twMerge(
            "flex gap-2 px-6 pt-6 md:items-center justify-between",
            !taskId ? "pb-6" : "md:p-0"
        )}
    >
        {!taskId ? (
            <span className="text-header">Get Started</span>
        ) : (
            <Link
                className={twMerge(
                    baseButtonStyles,
                    getButtonSizeStyle("small"),
                    getButtonVariantStyle("secondary"),
                    "gap-x-2"
                )}
                href={URI_GET_STARTED}
                onClick={() =>
                    PortalAnalytics.track("onboarding__close_task_list", {
                        task_id: taskId
                    })
                }
            >
                <ArrowLeft size={20} />
                Back
            </Link>
        )}
        <div className="flex gap-x-2 items-center">
            <ProgressBar
                className="w-20 md:w-40 flex-grow"
                complete={tasksComplete}
                total={TOTAL_ONBOARDING_TASKS}
                alwaysShowALittle
            />
            <span className="text-secondaryDark whitespace-nowrap text-small md:text-medium">
                {tasksComplete}/{TOTAL_ONBOARDING_TASKS} tasks
            </span>
        </div>
    </div>
);
