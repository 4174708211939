import { useState } from "react";
import Link from "next/link";
import PortalAnalytics from "PortalAnalytics";
import { Button, getButtonStyles } from "components/common/v2/button/Button";
import { URI_HOME } from "constants/urls";
import { useDismissGuide } from "./useDismissGuide";
import { launchFireworks } from "utils/fire-the-confetti";
import { Modal } from "components/common/v2/modal/Modal";
import { useAuth } from "hooks/use-auth";

export const DISMISS_COPY_MOVE_GUIDE = {
    early: "Are you sure? The Get Started guide will still be available in the Learn tab.",
    complete:
        "Congratulations on completing Get Started! Click dismiss to move the guide to the Learn tab."
};

export const DISMISS_COPY_REMOVE_GUIDE_TRAINING_V3 = {
    early: "Are you sure? The Get Started guide will be permanently dismissed.",
    complete:
        "Congratulations on completing Get Started! Click dismiss to permanently remove the guide."
};

/** A Skip for now button that takes you home, and a Dismiss Guide button. Skip button disappears when the guide is finished. */
export const DismissGuideButtons = ({
    allTasksComplete
}: {
    allTasksComplete: boolean;
}): JSX.Element => {
    const { isFeatureEnabled } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const dismissGuide = useDismissGuide();

    const closeModal = () => setIsOpen(false);

    return (
        <div className="flex flex-col gap-y-2 items-center pt-6">
            {!allTasksComplete && (
                <Link
                    className={getButtonStyles({ variant: "secondary" })}
                    href={URI_HOME}
                    onClick={() => PortalAnalytics.track("onboarding__skip_for_now")}
                >
                    Skip for now
                </Link>
            )}
            <Button
                className="w-fit"
                variant={!allTasksComplete ? "text" : "primary"}
                onClick={() => {
                    setIsOpen(true);
                    if (allTasksComplete) launchFireworks(5);
                }}
            >
                Dismiss guide
            </Button>
            {isOpen && (
                <Modal
                    open={isOpen}
                    title="Dismiss Guide"
                    onClose={closeModal}
                    footerButtons={{
                        secondary: { onClick: closeModal, children: "Cancel" },
                        primary: {
                            color: "error",
                            onClick: async () => {
                                PortalAnalytics.track("onboarding__dismiss_incomplete");
                                await dismissGuide();
                            },
                            children: "Dismiss"
                        }
                    }}
                >
                    {
                        (isFeatureEnabled("training_v3")
                            ? DISMISS_COPY_REMOVE_GUIDE_TRAINING_V3
                            : DISMISS_COPY_MOVE_GUIDE)[allTasksComplete ? "complete" : "early"]
                    }
                </Modal>
            )}
        </div>
    );
};
