import { useSWRGlobalMutation } from "contexts/SWRContext";
import { UserProfile } from "api/user/profile";
import { URL_USER_PROFILE } from "constants/urls";
import { useAuth } from "hooks/use-auth";

/** Returns a callback that sets the user's hide_onboarding flag to true and redirects them to the home page */
export const useDismissGuide = (): (() => Promise<void>) => {
    const { refresh: refreshUser } = useAuth();
    const { trigger: patchUser } = useSWRGlobalMutation<
        void,
        Error,
        { data: Partial<UserProfile> }
    >({ url: URL_USER_PROFILE, method: "PATCH" });

    return () =>
        patchUser({ data: { hide_onboarding: true } }).then(() =>
            // Forcing the user to refresh will force the redirect home
            refreshUser()
        );
};
